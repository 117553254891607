import { breakpoints } from "@wpf-admin-ui/styles/themes/extend";
import { useMediaQuery } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";

export function usePreviousValue<T>(value: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export function useIsPC(): boolean {
  const [isPC, setIsPC] = useState(false);
  const [isPCByMediaQuery] = useMediaQuery(`(min-width: ${breakpoints.pc})`);

  useEffect(() => {
    if (isPCByMediaQuery !== isPC) {
      setIsPC(isPCByMediaQuery);
    }
  }, [isPC, isPCByMediaQuery]);

  return isPC;
}
