import { Typography } from "@wpf-admin-ui/components/essentials/typography";
import { useHelloWorldQuery } from "@wpf-admin-ui/gql/generated/graphql";
import { Box, Spinner } from "@chakra-ui/react";
import { type NextPage } from "next";
import { gql } from "urql";
import { useGraphQLError } from "@wpf-admin-ui/hooks/useGraphQLError";
import { SheSpinner } from "@wpf-admin-ui/components/essentials/spinner/SheSpinner";

gql(`
query helloWorld {
  helloWorld
}`);

const TopPage: NextPage = () => {
  const [{ data, fetching, error }] = useHelloWorldQuery();

  useGraphQLError(error);

  if (fetching) {
    return <SheSpinner />;
  }

  return <Typography variant="heading1">{data?.helloWorld}</Typography>;
};

export default TopPage;
