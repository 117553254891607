import { type ComponentStyleConfig, theme } from "@chakra-ui/react";
import token from "@sheinc/design-tokens";

export const Badge: ComponentStyleConfig = {
  sizes: {
    md: {
      width: "65px",
      height: "25px",
    },
  },
  baseStyle: {
    textTransform: "capitalize",
  },
  variants: {
    required: (props) => {
      return {
        ...theme.components.Badge.variants?.outline(props),
        borderColor: token.color.function.dangerDefault.value,
        boxShadow: "inset 0 0 0 1px",
        color: token.color.text.danger.value,
        px: "0.5rem",
        py: "0.125rem",
        borderRadius: "0",
      };
    },
    optional: (props) => {
      return {
        ...theme.components.Badge.variants?.outline(props),
        borderColor: token.color.primitive.gray100.value,
        boxShadow: "inset 0 0 0 1px",
        color: token.color.text.lightest.value,
        px: "0.5rem",
        py: "0.125rem",
        borderRadius: "0",
      };
    },
    tag: (props) => {
      return {
        ...theme.components.Badge.variants?.solid(props),
        backgroundColor: token.color.object.shepinkDarken.value,
        color: token.color.text.white.value,
        px: "0.5rem",
        py: "0.125rem",
        borderRadius: "0",
      };
    },
  },
};
