import { Box, Icon } from "@chakra-ui/react";
import { useRouter } from "next/router";
import token from "@sheinc/design-tokens";
import { Typography } from "@wpf-admin-ui/components/essentials/typography";
import { Link } from "@wpf-admin-ui/components/essentials/link";

interface MenuItemProps {
  icon: JSX.Element;
  label?: string;
  to: string;
  isOpen: boolean;
  activeColor: string;
}

const MenuItem: React.VFC<MenuItemProps> = ({ icon, label, to, isOpen, activeColor }) => {
  const router = useRouter();
  const isActive = router.pathname === to;

  return (
    <Link href={to} w="100%">
      <Box
        display="flex"
        alignItems="center"
        backgroundColor={isActive ? activeColor : "transparent"}
        borderLeft={
          isActive ? `3px solid ${token.color.function.primaryDefault.value as string}` : "3px solid transparent"
        }
        cursor="pointer"
        pl={4}
        position="relative"
        width="100%"
        height="50px"
      >
        <Icon boxSize={10} minWidth="24px" mt={3.5} color={token.color.text.lightest.value}>
          {icon}
        </Icon>
        <Box
          as="span"
          position={isOpen ? "static" : "absolute"}
          flexShrink={isOpen ? 0 : 1}
          maxWidth={isOpen ? "auto" : "0"}
          overflow="hidden"
          visibility={isOpen ? "visible" : "hidden"}
          transition="all 0.5s ease-in-out"
        >
          <Typography color={token.color.text.lightest.value} variant="body1b">
            {label}
          </Typography>
        </Box>
      </Box>
    </Link>
  );
};

export default MenuItem;
