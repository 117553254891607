import { type ComponentStyleConfig } from "@chakra-ui/react";
import token from "@sheinc/design-tokens";

export const Checkbox: ComponentStyleConfig = {
  baseStyle: {
    control: {
      _checked: {
        bg: token.color.function.primaryDefault.value,
        borderColor: token.color.function.primaryDefault.value,
        _hover: {
          bg: token.color.function.primaryDefault.value,
          borderColor: token.color.function.primaryDefault.value,
        },
      },
    },
  },
};
