import { type VFC } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { Header } from "@wpf-admin-ui/components/fragments/header";
import { useCurrentUser } from "@wpf-admin-ui/contexts";

interface DefaultLayoutProps {
  readonly children: React.ReactNode;
}

export const DefaultLayout: VFC<DefaultLayoutProps> = (props: DefaultLayoutProps) => {
  const { children } = props;
  const currentUser = useCurrentUser();
  return (
    <Box>
      {currentUser && <Header name={currentUser.name} profilePhotoUrl={currentUser.profilePhotoUrl} />}
      <Flex>
        <Box as="main" w={{ pc: "90%", sp: "100%" }} m="0 auto" px={{ sp: "1.5rem" }} mb="6rem">
          {children}
        </Box>
      </Flex>
    </Box>
  );
};
