import { type ComponentStyleConfig } from "@chakra-ui/react";

export const Text: ComponentStyleConfig = {
  variants: {
    heading1: {
      fontSize: "40px",
      fontWeight: "700",
      letterSpacing: "0.42px",
    },
    heading2: {
      fontSize: "32px",
      letterSpacing: "0.42px",
    },
    heading2b: {
      fontSize: "32px",
      fontWeight: "700",
      letterSpacing: "0.42px",
    },
    heading3: {
      fontSize: "24px",
      letterSpacing: "0.42px",
    },
    heading3b: {
      fontSize: "24px",
      fontWeight: "700",
      letterSpacing: "0.42px",
    },
    heading4: {
      fontSize: "20px",
      letterSpacing: "0.42px",
    },
    heading4b: {
      fontSize: "20px",
      fontWeight: "700",
      letterSpacing: "0.42px",
    },
    heading5: {
      fontSize: "16px",
      letterSpacing: "0.42px",
    },
    heading5b: {
      fontSize: "20px",
      fontWeight: "700",
      letterSpacing: "0.42px",
    },
    heading6: {
      fontSize: "18px",
      letterSpacing: "0.42px",
    },
    heading6b: {
      fontSize: "18px",
      fontWeight: "700",
      letterSpacing: "0.42px",
    },
    body1: {
      fontSize: "16px",
      letterSpacing: "0.42px",
    },
    body1b: {
      fontSize: "16px",
      fontWeight: "700",
      letterSpacing: "0.42px",
    },
    body2: {
      fontSize: "14px",
      letterSpacing: "0.42px",
    },
    body2b: {
      fontSize: "14px",
      fontWeight: "700",
      letterSpacing: "0.42px",
    },
    body3: {
      fontSize: "12px",
      letterSpacing: "0.42px",
    },
    body3b: {
      fontSize: "12px",
      fontWeight: "700",
      letterSpacing: "0.42px",
    },
    body4: {
      fontSize: "10px",
      letterSpacing: "0.42px",
    },
    body4b: {
      fontSize: "10px",
      fontWeight: "700",
      letterSpacing: "0.42px",
    },
  },

  defaultProps: {
    variant: "h1",
  },
};
