import { type ComponentStyleConfig } from "@chakra-ui/react";
import token from "@sheinc/design-tokens";

export const Select: ComponentStyleConfig = {
  variants: {
    outline: {
      field: {
        _invalid: {
          backgroundColor: "bg.invalid",
          boxShadow: "none",
          borderColor: token.color.function.dangerDefault.value,
        },
      },
    },
  },
};
