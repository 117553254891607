import { useToast, type UseToastOptions } from "@chakra-ui/react";
import { Snackbar } from "./Snackbar";
import { useIsPC } from "@wpf-admin-ui/utils/hooks";
import { useEffect, useRef } from "react";
import { WideSnackbar } from "@wpf-admin-ui/components/essentials/snackbar/WideSnackbar";

interface BaseProps {
  snackbarVariant?: "default" | "wide";
}

type ShowSnackbarOptionsWithMessage = BaseProps &
  UseToastOptions & {
    message: string;
    // render cannot be set if message is set
    render?: never;
    isDanger?: boolean;
  };

type ShowSnackbarOptionsWithRender = BaseProps &
  UseToastOptions & {
    render: UseToastOptions["render"];
    // message cannot be set if render is set
    message?: never;
  };

export type ShowSnackbarOptions = ShowSnackbarOptionsWithMessage | ShowSnackbarOptionsWithRender;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type -- implicitly infer return type
export const useSnackbar = () => {
  const snackbar = useToast();
  const isPC = useIsPC();
  const isPCRef = useRef(isPC);
  useEffect(() => {
    isPCRef.current = isPC;
  }, [isPC]);

  const showSnackbar = ({ snackbarVariant = "default", ...options }: ShowSnackbarOptions): void => {
    snackbar({
      position: isPCRef.current ? "bottom-right" : "bottom",
      containerStyle: {
        width: "100%",
        maxWidth: "100%",
      },
      // eslint-disable-next-line @typescript-eslint/unbound-method -- intended
      render: ({ onClose }) => {
        if (snackbarVariant === "wide") {
          return (
            <WideSnackbar onClose={onClose} {...options}>
              {options.message}
            </WideSnackbar>
          );
        } else {
          return (
            <Snackbar onClose={onClose} {...options}>
              {options.message}
            </Snackbar>
          );
        }
      },
      ...options,
    });
  };

  return { showSnackbar };
};
