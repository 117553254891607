import { isAllowedPathForCurrentUser, isAuthPath } from "@wpf-admin-ui/components/auth/authorization";
import { SheSpinner } from "@wpf-admin-ui/components/essentials/spinner/SheSpinner";
import { NotFoundPage } from "@wpf-admin-ui/components/fragments/404/NotFoundPage";
import { useCurrentUser } from "@wpf-admin-ui/contexts";
import { useRouter } from "next/router";

import React, { type FC, type ReactNode } from "react";

export interface GuardProps {
  readonly children: ReactNode;
  readonly isRedirecting: boolean;
}

export const AuthGuard: FC<GuardProps> = ({ children, isRedirecting }) => {
  const router = useRouter();
  const currentUser = useCurrentUser();
  if (isAuthPath(router)) {
    return <>{children}</>;
  }
  if (isAllowedPathForCurrentUser(router, currentUser)) {
    return <>{children}</>;
  }
  return isRedirecting ? <SheSpinner /> : <NotFoundPage />;
};
