import nookies from "nookies";
import { type CurrentUser, CurrentUserDocument, type FeatureFlag } from "@wpf-admin-ui/gql/generated/graphql";
import { type NextPageContext } from "next";
import { createGraphqlClient } from "@wpf-admin-ui/utils";
import { responseContainsAuthZError } from "@wpf-admin-ui/utils/errors";

export async function fetchCurrentUser(
  ctx: NextPageContext,
): Promise<[string | null, CurrentUser | null, boolean, FeatureFlag[]]> {
  const { token } = nookies.get(ctx);
  if (!token || typeof token !== "string") {
    return [null, null, false, []];
  }
  const client = createGraphqlClient(token);
  const currentUserResponse = await client.query(CurrentUserDocument, {}).toPromise();
  const isForbidden = responseContainsAuthZError(currentUserResponse);
  const currentUser = currentUserResponse.data?.currentUser;
  const enabledFeatures = currentUserResponse.data?.enabledFeatures ?? [];
  if (!currentUser) {
    nookies.destroy(ctx, "token");
  }
  return [token, currentUser as CurrentUser, isForbidden, enabledFeatures];
}
