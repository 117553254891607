import { defineStyle, defineStyleConfig } from "@chakra-ui/react";
import token from "@sheinc/design-tokens";

const variants = {
  primary: defineStyle({
    bg: token.color.button.primary.bgDefault.value,
    color: token.color.button.primary.textDefault.value,
    borderRadius: "112px",
    _hover: {
      _disabled: {
        bg: token.color.bg.gray.value,
        opacity: 1,
      },
    },
    _disabled: {
      bg: token.color.bg.gray.value,
      color: token.color.button.primary.textDisabled.value,
      opacity: 1,
    },
  }),
  secondary: defineStyle({
    bg: "transparent",
    color: token.color.button.secondary.textDefault.value,
    borderRadius: "112px",
    _hover: {
      bgColor: token.color.button.secondary.bgHover.value,
    },
  }),
  danger: defineStyle({
    bg: token.color.button.danger.bgDefault.value,
    color: token.color.button.danger.textDefault.value,
    borderRadius: "112px",
    _hover: {
      bgColor: token.color.button.danger.bgHover.value,
    },
  }),
  outline: defineStyle({
    bg: "transparent",
    color: token.color.text.primary.value,
    borderColor: token.color.button.primary.borderDefault.value,
    borderWidth: "1px",
    borderRadius: "112px",
    _hover: {
      bg: token.color.primitive.white.value,
    },
  }),
  "no-hover": defineStyle({
    _hover: {
      boxShadow: "none",
    },
  }),
  "transparent-with-icon": defineStyle({
    bg: "transparent",
    fontWeight: "bold",
    cursor: "pointer",
    _active: {
      bg: "transparent",
      transform: "none",
      borderColor: "transparent",
    },
    _focus: {
      boxShadow: "none",
    },
    _hover: {
      boxShadow: "none",
    },
  }),
  linkHover: defineStyle((props) => {
    return {
      _hover: {
        bg: token.color.object.gray1.value,
        color: token.color.function.primaryDefault.value,
        "> *": {
          fontWeight: props.fontWeight || "700",
        },
      },
      borderRadius: 0,
    };
  }),
  linkHoverSecondary: defineStyle((props) => {
    return {
      _hover: {
        "> *": {
          fontWeight: props.fontWeight || "700",
          color: token.color.function.primaryDefault.value,
        },
      },
      borderRadius: 0,
    };
  }),
};

const baseStyle = defineStyle({
  borderRadius: "15px",
  _focus: {
    boxShadow: "none",
  },
});

export const Button = defineStyleConfig({
  baseStyle,
  variants,
  defaultProps: {
    variant: "primary",
  },
});
