import { useSnackbar } from "@wpf-admin-ui/components/essentials/snackbar/useSnackBar";
import { createContext, type ReactElement, type ReactNode, useCallback, useEffect, useRef, useState } from "react";

interface ErrorObject {
  message: string;
}

export interface ErrorContextValue {
  error: null | ErrorObject;
  handleError: (error: ErrorObject) => void;
  clearError: () => void;
}

export const ErrorContext = createContext<ErrorContextValue>({
  error: null,
  handleError: () => {},
  clearError: () => {},
});

export const ErrorProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const { showSnackbar } = useSnackbar();
  const [error, setError] = useState<ErrorContextValue["error"]>(null);
  const prevError = useRef<ErrorObject | null>(null);

  const handleError = useCallback(({ message }: ErrorObject) => {
    setError({ message });
  }, []);

  const clearError = useCallback(() => {
    setError(null);
  }, [setError]);

  useEffect(() => {
    if (!error) return undefined;
    // 重複したエラーの表示は回避する
    if (prevError.current?.message === error.message) return undefined;

    prevError.current = error;

    showSnackbar({ message: error.message, isDanger: true });
  }, [error, showSnackbar]);

  return <ErrorContext.Provider value={{ error, handleError, clearError }}>{children}</ErrorContext.Provider>;
};
