import { Flex, HStack, Spacer } from "@chakra-ui/react";
import { type VFC } from "react";
import { IconImage } from "@wpf-admin-ui/components/essentials/icon";
import { Typography } from "@wpf-admin-ui/components/essentials/typography";

export interface HeaderProps {
  readonly profilePhotoUrl: string;
  readonly name: string;
}

export const Header: VFC<HeaderProps> = (props) => {
  const { profilePhotoUrl, name } = props;
  const iconFallbackSrc = "/images/default-user-profile.png";
  return (
    <Flex bg="white" m="0" p="1rem" zIndex={1}>
      <Spacer />
      <HStack>
        <IconImage
          src={profilePhotoUrl}
          fallbackSrc={iconFallbackSrc}
          boxSize={{ sp: "40px", pc: "32px" }}
          marginBottom="0px"
          boxShadow="0px"
        />
        <Typography variant="body3">{name}</Typography>
      </HStack>
    </Flex>
  );
};
