import { Typography } from "@wpf-admin-ui/components/essentials/typography";
import { DefaultLayout } from "@wpf-admin-ui/layouts/default";
import { Box, Image } from "@chakra-ui/react";

export const NotFoundPage: React.VFC = () => {
  return (
    <DefaultLayout>
      <Box mt={{ sp: "1.5rem", pc: "3rem" }}>
        <Typography variant="heading4" fontWeight="700">
          404 Not Found
        </Typography>
        <Typography variant="body1b" mt="0.5rem">
          ページが見つかりません。
        </Typography>
        <Image src="/images/404.png" mt="1.25rem" width={{ sp: "100%", pc: "400px" }} alt="" />
      </Box>
    </DefaultLayout>
  );
};
