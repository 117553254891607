import { type ComponentStyleConfig } from "@chakra-ui/react";
export const Drawer: ComponentStyleConfig = {
  variants: {
    "with-shadow": {
      placement: "right",
      boxShadow: "0 0 2px 2px #efdfde",
      bgColor: "red",
    },
  },
};
