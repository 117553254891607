import { createContext, useContext } from "react";
import { type Client } from "urql";

interface GatewayClientContextProps {
  client: Client | null;
}
const initialGatewayClientContext: GatewayClientContextProps = {
  client: null,
};
export const GatewayClientContext = createContext<GatewayClientContextProps>(initialGatewayClientContext);

export const useGatewayClientContext = (): GatewayClientContextProps => {
  return useContext(GatewayClientContext);
};
