import { radioAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import tokens from "@sheinc/design-tokens";

// eslint-disable-next-line @typescript-eslint/unbound-method -- intended
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(radioAnatomy.keys);

const baseStyle = definePartsStyle({
  control: {
    borderRadius: "12px",
    borderWidth: "1px",
    borderColor: tokens.color.object.gray3.value,
    _checked: {
      backgroundColor: tokens.color.object.white.value,
      borderColor: tokens.color.object.sheblueDarken.value,
      _before: {
        backgroundColor: tokens.color.object.sheblueDarken.value,
        width: "75%",
        height: "75%",
      },
      _disabled: {
        backgroundColor: tokens.color.object.gray1.value,
        borderColor: tokens.color.object.gray3.value,
        _before: {
          backgroundColor: tokens.color.object.gray3.value,
          width: "75%",
          height: "75%",
        },
      },
    },
    _disabled: {
      backgroundColor: tokens.color.object.gray1.value,
      borderColor: tokens.color.object.gray3.value,
      _before: {
        backgroundColor: tokens.color.object.gray3.value,
        width: "75%",
        height: "75%",
      },
    },
  },
  label: {
    fontSize: "14px",
    marginInlineStart: "6px",
  },
});

// デフォルトのフォントサイズを変更するにはsizesを調節する必要がある
// https://github.com/chakra-ui/chakra-ui/blob/78dfd7ed5925e0d3a792c7f751ce18ac0c578c2a/packages/theme/src/components/radio.ts#L39-L52
const sizes = {
  md: definePartsStyle({
    control: { w: "4", h: "4" },
    label: { fontSize: "sm" }, // 14pxに変える
  }),
  lg: definePartsStyle({
    control: { w: "5", h: "5" },
    label: { fontSize: "lg" },
  }),
  sm: definePartsStyle({
    control: { width: "3", height: "3" },
    label: { fontSize: "sm" },
  }),
};

export const Radio = defineMultiStyleConfig({ sizes, baseStyle });
