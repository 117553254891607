export const Link = {
  decoration: "none",
  baseStyle: {
    _hover: {
      textDecoration: "none",
    },
    _focus: {
      boxShadow: "none",
    },
  },
};
