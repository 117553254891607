import { type FC } from "react";
import { Box, Image } from "@chakra-ui/react";
import { Typography } from "@wpf-admin-ui/components/essentials/typography";
import token from "@sheinc/design-tokens";

export const NotFound: FC = () => (
  <Box
    mx={{ sp: "1rem", pc: "9.375rem" }}
    mt={{ sp: "2rem", pc: "2rem" }}
    w={{ sp: "90%", pc: "1021px" }}
    mb={{ sp: "6rem", pc: "10rem" }}
  >
    <Typography variant="body1" color={token.color.text.default.value} letterSpacing="1.6px" mb="2rem" mt="3.5rem">
      お探しのページは見つかりませんでした。ページが削除されたか、URLが変更された可能性があります。
    </Typography>
    <Image
      src="/images/she_chan_sorry.png"
      alt="she_chan_sorry_image"
      w={{ sp: "100%", pc: "400px" }}
      h={{ sp: "100%", pc: "160px" }}
      mt="1.9375rem"
    />
  </Box>
);
