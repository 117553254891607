import { extendTheme } from "@chakra-ui/react";
import { fonts } from "./fonts";
import {
  Badge,
  Button,
  Text,
  Card,
  MainPanel,
  PanelContainer,
  PanelContent,
  Drawer,
  Link,
  Textarea,
  Select,
  Checkbox,
  Radio,
} from "@wpf-admin-ui/styles/themes/components";
import token from "@sheinc/design-tokens";

// sp = 0px ~ 640px
// tablet = 640px ~ 1200px
// pc = 1200px ~
export const breakpoints = {
  sm: "",
  md: "",
  lg: "",
  xl: "",
  sp: "0px",
  tablet: "640px",
  pc: "1200px",
};

export const theme = extendTheme({
  fonts,
  // Design Token の値を調整して利用が必要な場合にのみ定義
  // （原則 Design Token の値を利用してください）
  colors: {
    // DesignToken側の対応色が未定のため一時的に定義しているもの
    // カラーが確定した場合はDesignToken側の値に置き換えてください
    fixme: {
      bgSnackbar: "#EDF7EC",
      bgEntryWorkCard: "#71b16c4d",
    },
    bg: {
      // バリデーションエラーの背景色（アルファ値を追加）
      invalid: `${token.color.function.dangerDefault.value as string}2B`,
    },
  },
  breakpoints,
  components: {
    Badge,
    Button,
    Text,
    Card,
    MainPanel,
    PanelContainer,
    PanelContent,
    Drawer,
    Link,
    Textarea,
    Select,
    Checkbox,
    Radio,
  },
});
