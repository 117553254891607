import { Box, type BoxProps, Icon, Image } from "@chakra-ui/react";
import { Typography } from "@wpf-admin-ui/components/essentials/typography";
import { type FC } from "react";
import token from "@sheinc/design-tokens";
import { FaExclamationTriangle } from "react-icons/fa";

interface Props {
  readonly onClose: () => void;
  readonly children: React.ReactNode;
  readonly isDanger?: boolean;
}
export const WideSnackbar: FC<Props> = ({ onClose, children, isDanger, ...boxProps }) => {
  return (
    <Box
      display="flex"
      gap="6px"
      bg={isDanger ? token.color.bg.danger.value : token.color.bg.success.value}
      py="0.75rem"
      px="1rem"
      minH="3rem"
      justifyContent={{ pc: "space-between" }}
      alignItems="center"
      boxShadow="2px 2px 12px rgba(0, 0, 0, 0.08)"
      borderRadius="8px"
      {...boxProps}
    >
      {isDanger ? (
        <Icon as={FaExclamationTriangle} fill={token.color.text.danger.value} />
      ) : (
        <Image w="1.25rem" h="1.25rem" src="/images/check_circle_outline.svg" alt="check-icon" />
      )}

      <Typography
        variant="body2b"
        color={isDanger ? token.color.text.danger.value : token.color.text.primary.value}
        letterSpacing="1.6px"
        lineHeight="1.5rem"
        textAlign="left"
        flex="1"
        whiteSpace="pre-line"
      >
        {children}
      </Typography>

      <Box display="flex" alignItems="center" ml={{ sp: "auto", pc: "0px" }} cursor="pointer">
        <Image
          display="inline"
          verticalAlign="middle"
          onClick={onClose}
          src="/images/close.svg"
          w="0.75rem"
          h="0.75rem"
          alt="close-icon"
        />
      </Box>
    </Box>
  );
};
