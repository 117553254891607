import { type CurrentUser } from "@wpf-admin-ui/gql/generated/graphql";
import { type NextRouter, useRouter } from "next/router";
import { useEffect, useState } from "react";

function getAuthPageUrl(): string {
  const query = new URLSearchParams({ redirectUrl: location.href }).toString();
  return `/auth?${query}`;
}

const needsRedirect = (currentUser: CurrentUser | null, isForbidden: boolean, router: NextRouter): boolean => {
  return (
    !currentUser &&
    // 権限が無い場合はNotFoundを表示するのでRedirectはしない
    !isForbidden &&
    !(router.pathname.startsWith("/auth") || router.pathname.startsWith("/set_token"))
  );
};

export function useRequestSignIn(currentUser: CurrentUser | null, isForbidden: boolean): { isRedirecting: boolean } {
  const router = useRouter();
  // リダイレクト中だと外部にわかるように状態を管理する
  // この情報をもとにSpinnerを出しっぱなしにするかどうかが判断できたりする
  const [isRedirecting, setIsRedirecting] = useState(needsRedirect(currentUser, isForbidden, router));

  useEffect(() => {
    if (needsRedirect(currentUser, isForbidden, router)) {
      void router.push(getAuthPageUrl());
      setIsRedirecting(true);
    }
  }, [router, currentUser, isForbidden]);

  return { isRedirecting };
}
