import { cardAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

// eslint-disable-next-line @typescript-eslint/unbound-method -- intended
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(cardAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    p: "22px",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    position: "relative",
    minWidth: "0px",
    wordWrap: "break-word",
    backgroundClip: "border-box",
  },
});

const variants = {
  panel: definePartsStyle((props) => ({
    container: {
      bg: props.colorMode === "dark" ? "gray.700" : "white",
      width: "100%",
      boxShadow: "0px 3.5px 5.5px rgba(0, 0, 0, 0.02)",
      borderRadius: "15px",
    },
  })),
};

export const Card = defineMultiStyleConfig({
  baseStyle,
  variants,
  defaultProps: {
    variant: "panel",
  },
});

// export const Card = definePartsStyle({
//   baseStyle: {
//     p: "22px",
//     display: "flex",
//     flexDirection: "column",
//     width: "100%",
//     position: "relative",
//     minWidth: "0px",
//     wordWrap: "break-word",
//     backgroundClip: "border-box",
//   },
//   variants: {
//     panel: (props) => ({
//       bg: props.colorMode === "dark" ? "gray.700" : "white",
//       width: "100%",
//       boxShadow: "0px 3.5px 5.5px rgba(0, 0, 0, 0.02)",
//       borderRadius: "15px",
//     }),
//   },
//   defaultProps: {
//     variant: "panel",
//   },
// });
